@use "base";

@include base.show-hide();

[data-topNavComponent] {
  .main-title {
    color: base.map-deep-get(base.$colours, white, "primary") !important;
  }

  .sidenav-container {
    height: 100%;
  }

  .sidenav {
    width: base.map-deep-get(base.$breakpoints, "mobile", "min");

    .mat-toolbar-row {
      background-color: base.map-deep-get(base.$colours, gray, "primary");
      padding: base.$size;
      margin-bottom: 0;

      > .flex-child {
        button {
          margin: 0;
          background-color: base.map-deep-get(base.$colours, transparent, "primary");
        }
      }
    }

    .mat-nav-list {
      padding: base.$size;
      padding-top: 0;
    }
  }

  .sidenav .mat-toolbar {
    background: inherit;
  }

  .mat-nav-list {
    padding-top: 0;
  }

  .mat-toolbar.mat-primary {
    position: sticky;
    top: 0;

    // only apply to button right of menu
    button:last-child {
      margin-right: 2.5em;
    }
  }

  .locale {
    text-transform: uppercase;
  }

  .toolbar-icon {
    padding: 0 14px;
  }

  [data-right-third] {
    display: flex;
    justify-content: flex-end;

    button:focus-visible {
      outline: 1px auto;
    }
  }

  [data-hamburger-menu] {
    display: flex;
    margin-right: 1em;
    right: 0;

    .mat-icon {
      color: base.map-deep-get(base.$colours, white, "primary") !important;
    }
  }

  [data-header] {
    mat-toolbar {
      background-color: base.map-deep-get(base.$colours, black, "primary");
      height: auto;
      padding-top: base.multiply-two-numbers(base.$size, 1.25);
      padding-bottom: base.multiply-two-numbers(base.$size, 1.25);
      padding-left: base.$size;
      padding-right: base.$size;

      a {
        line-height: 0;
      }

      button.button {
        margin: 0;
        margin-right: base.$size;
      }

      &.flex.flex--cols-3 {
        > .flex-child {
          padding: 0;
          margin: 0;

          &:first-child {
            img {
              height: base.multiply-two-numbers(base.$size, 1.8);

              @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
                height: base.multiply-two-numbers(base.$size, 1.5625);
              }
            }
          }

          &:last-child {
            > button {
              font-size: base.multiply-two-numbers(base.$size, 1.25);

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          @media (max-width: base.map-deep-get(base.$breakpoints, "tablet", "max")) {
            width: base.divide-two-numbers(100%, 3);
          }
        }
      }
    }
  }

  header.print--show {
    width: 100%;
    padding-left: base.$size;
    padding-right: base.$size;
    margin-bottom: base.$size;

    h1 {
      color: base.map-deep-get(base.$colours, black, "primary");
      font-size: base.multiply-two-numbers(base.$size, 2);
      text-align: right;
      display: block;
      position: relative;
    }

    img {
      float: left;
      height: base.multiply-two-numbers(base.$size, 2.35);
      margin-top: base.multiply-two-numbers(base.$size, 0.1);
    }

    * {
      margin: 0;
      padding: 0;
    }
  }

  mat-sidenav-container {
    background-color: base.map-deep-get(base.$colours, white, "primary");
  }
}
