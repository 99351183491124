@use "base";

.flagComponent {
  height: base.multiply-two-numbers(base.$size, 2.5);
  margin: 0 !important;
  padding: 0 !important;

  mat-icon.mat-icon {
    cursor: pointer;

    &.mat-icon--text {
      margin-top: base.divide-two-numbers(base.$size, base.negate-number(2));
      margin-left: base.divide-two-numbers(base.$size, 2);
    }

    &.mat-icon--button {
      vertical-align: top;
    }

    &.mat-icon--not-set {
      font-family: "Roboto", sans-serif;
      visibility: hidden;

      &::before {
        content: "Incorrect use of <app-flag>; missing nextTo or proper nextTo value";
        font-family: inherit;
        font-size: base.$size;
        color: base.map-deep-get(base.$colours, red, "primary");
        border: base.multiply-two-numbers(base.$size, 0.1) solid
          base.map-deep-get(base.$colours, red, "primary");
        background-color: base.map-deep-get(base.$colours, white, "primary");
        display: block;
        max-width: base.map-deep-get(base.$breakpoints, "mobile", "min");
        width: 100vw;
        z-index: 1000;
        position: relative;
        padding: base.$size;
        white-space: normal;
        box-shadow: 0 0 base.$size
          base.map-deep-get(base.$colours, red, "primary");
        border-radius: base.$size;
        visibility: visible;
      }
    }
  }
}
