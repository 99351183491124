@use "base";

[data-addCropRotationDialogComponent] {
  .mat-mdc-dialog-content {
    width: base.multiply-two-numbers(base.$size, 58);

    [data-addCropRotationDialog].flex {
      > .flex-child {
        &:nth-child(3) {
          margin-top: 0;
          margin-bottom: base.$size;
        }

        &:nth-child(2n + 4) {
          padding-left: 0;
          padding-right: base.divide-two-numbers(base.$size, 2);
        }

        &:nth-child(2n + 5) {
          padding-left: base.divide-two-numbers(base.$size, 2);
          padding-right: 0;
        }

        &:nth-child(4).flex {
          flex-wrap: nowrap;

          @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
            flex-wrap: wrap;
          }

          > .flex-child {
            width: auto !important;

            @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
              width: 100% !important;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        &.flex {
          > .flex-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
