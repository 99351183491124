@use "base";

.line-chart-id {
  display: block;
  min-height: base.multiply-two-numbers(base.$size, 26);
  background-color: base.map-deep-get(base.$colours, white, "primary");
  height: base.multiply-two-numbers(base.$size, 26);

  @media print {
    break-inside: avoid;
  }

  canvas {
    height: inherit;
    min-height: inherit;
    width: base.multiply-two-numbers(base.$size, 52);

    @media print {
      break-inside: avoid;
    }
  }
}
