export class ConversionConstants {
  // Conversion constants
  //
  // Conversion constants are in following form:
  // UNITS_X_Y
  // To convert from X to Y - multiply
  // To convert from Y to X - divide
  //
  // Length
  public static readonly LENGTH_CM_IN = 0.3937;
  public static readonly LENGTH_MM_IN = 0.03937;
  public static readonly LENGTH_M_FT = 3.2808;
  // 3.281;
  public static readonly LENGTH_KM_MI = 0.62137;
  // Volume
  public static readonly VOLUME_L_GAL = 0.21996;
  public static readonly VOLUME_L_USGAL = 0.26396;
  public static readonly VOLUME_GAL_USGAL = 1.2;
  public static readonly VOLUME_CU_M_CU_YD = 1.308;
  public static readonly VOLUME_CU_M_GAL = 219.96;
  public static readonly VOLUME_CU_M_USGAL = 263.96;
  public static readonly VOLUME_CU_M_HA = 22.5;
  public static readonly VOLUME_GAL_AC = 2000;
  public static readonly VOLUME_USGAL_AC = 2400;
  // Volume Flow Rate
  public static readonly VOLUME_LPS_CFM = 2.11888;
  // Mass
  public static readonly MASS_KG_LB = 2.20462;
  public static readonly MASS_TONNE_TON = 1.0989;
  // Yield
  public static readonly YIELD_TONNEHA_TONAC = 0.44444;
  // Area
  public static readonly AREA_HA_AC = 2.4691;
  // 2.47;
  // Pipe Diameter
  public static readonly PIPE_DIAMETER = 0.04;

  //Soil Erosion: tonne/ha <-> ton/ac
  public static readonly SOILEROSION_TONNEHA_TONAC = 0.44609;

  // Unit symbol constants

  // Length
  public static readonly MM = 'mm';
  public static readonly CM = 'cm';
  public static readonly M = 'm';
  public static readonly M_S = 'm/s';
  public static readonly KM = 'km';
  public static readonly IN = 'in';
  public static readonly FT = 'ft';
  public static readonly FT_S = 'ft/s';
  public static readonly MI = 'mi';

	public static readonly CM_ACCESSIBILITY = "cm";
	public static readonly M_ACCESSIBILITY = "m";
	public static readonly M_S_ACCESSIBILITY = "meters.per.second";
	public static readonly MM_ACCESSIBILITY = "mm";
	public static readonly KM_ACCESSIBILITY = "km";
	public static readonly IN_ACCESSIBILITY = "in";
	public static readonly FT_ACCESSIBILITY = "ft";
	public static readonly FT_S_ACCESSIBILITY = "feet.per.second";
	public static readonly MI_ACCESSIBILITY = 'mi';

  // Volume
  public static readonly CU_M = 'm³';
  public static readonly CU_FT = 'ft³';
  public static readonly CU_M_S = 'm³/s';
  public static readonly CU_FT_S = 'ft³/s';
  public static readonly L = 'L';
  public static readonly GAL = 'gal';
  public static readonly US_GAL = 'USgal';
  public static readonly CU_YD = 'yd³';
  public static readonly L_HA = 'L/ha';
  public static readonly GAL_AC = 'gal/ac';
  public static readonly CU_M_HA = 'm³/ha';
  public static readonly US_GAL_AC = 'USgal/ac';
  public static readonly L_SQ_M = 'L / m²';
  public static readonly GAL_SQ_FT = 'gal / ft²';
  public static readonly US_GAL_SQ_FT = 'USgal / ft²';

	public static readonly CU_M_ACCESSIBILITY = "cubic.meters";
	public static readonly CU_FT_ACCESSIBILITY = "cubic.feet";
	public static readonly CU_M_S_ACCESSIBILITY = "cubic.meters.per.second";
	public static readonly CU_FT_S_ACCESSIBILITY = "cubic.feet.per.second";
	public static readonly L_ACCESSIBILITY = "liters";
	public static readonly GAL_ACCESSIBILITY = "gallons";
	public static readonly US_GAL_ACCESSIBILITY = "us.gallons";
	public static readonly CU_YD_ACCESSIBILITY = "cubic.yards";
	public static readonly L_HA_ACCESSIBILITY = "liters.per.hectare";
	public static readonly GAL_AC_ACCESSIBILITY = "gallons.per.acre";
	public static readonly CU_M_HA_ACCESSIBILITY = "cubic.meters.per.hectare";
	public static readonly US_GAL_AC_ACCESSIBILITY = "us.gallons.per.acre";
	public static readonly L_SQ_M_ACCESSIBILITY = "liters.per.square.meter";
	public static readonly GAL_SQ_FT_ACCESSIBILITY = "gallons.per.square.foot";
	public static readonly US_GAL_SQ_FT_ACCESSIBILITY = "us.gallons.per.square.foot";

  // Volume Flow Rate
  public static readonly LPS = 'L/s';
  public static readonly CFM = 'cfm';

	public static readonly LPS_ACCESSIBILITY = "liters.per.second";
	public static readonly CFM_ACCESSIBILITY = "cubic.feet.per.minute";

  // Mass
  public static readonly KG = 'kg';
  public static readonly LB = 'lb';
  public static readonly TONNE = 'tonne';
  public static readonly TON = 'ton';
  public static readonly KG_HA = 'kg/ha';
  public static readonly LB_AC = 'lb/ac';
  public static readonly KG_TONNE = 'kg/tonne';
  public static readonly LB_TON = 'lb/ton';
  public static readonly TONNE_HA = 'tonne/ha';
  public static readonly TON_AC = 'ton/ac';
  public static readonly TONNE_HA_YEAR = 'tonne/ha/year';
  public static readonly TON_AC_YEAR = 'ton/ac/year';
  public static readonly BU_AC = 'bu/ac';

	public static readonly KG_ACCESSIBILITY = "kilograms";
	public static readonly LB_ACCESSIBILITY = "pounds";
	public static readonly TONNE_ACCESSIBILITY = "tonne";
	public static readonly TON_ACCESSIBILITY = "ton";
	public static readonly KG_HA_ACCESSIBILITY = "kilograms.per.hectare";
	public static readonly LB_AC_ACCESSIBILITY = "pounds.per.acre";
	public static readonly KG_TONNE_ACCESSIBILITY = "kilograms.per.tonne";
	public static readonly LB_TON_ACCESSIBILITY = "pounds.per.ton";
	public static readonly TONNE_HA_ACCESSIBILITY = "tonnes.per.hectare";
	public static readonly TON_AC_ACCESSIBILITY = "tons.per.acre";
	public static readonly TONNE_HA_YEAR_ACCESSIBILITY = "tonnes.per.hectare.per.year";
	public static readonly TON_AC_YEAR_ACCESSIBILITY = "tons.per.acre.per.year";
	public static readonly BU_AC_ACCESSIBILITY = "bushels.per.acre";

  // Yield
  public static readonly TONHAC = 'ton/hac';
  public static readonly TONAC = 'ton/ac';

	public static readonly TONHAC_ACCESSIBILITY = "tons.per.hectare";
	public static readonly TONAC_ACCESSIBILITY = "tons.per.acre";

  // Area
  public static readonly HA = 'ha';
  public static readonly AC = 'ac';
  public static readonly SQ_M = 'm²';
  public static readonly SQ_FT = 'ft²';

	public static readonly HA_ACCESSIBILITY = "ha";
	public static readonly AC_ACCESSIBILITY = "ac";
	public static readonly SQ_M_ACCESSIBILITY = "square.meters";
	public static readonly SQ_FT_ACCESSIBILITY = "square.feet";

  // Area Cost
  public static readonly $_HA = '$/ha';
  public static readonly $_AC = '$/ac';

	public static readonly $_HA_ACCESSIBILITY = "dollars.per.hectare";
	public static readonly $_AC_ACCESSIBILITY = "dollars.per.acre";

  // Yield Price
  public static readonly $_TONNE = '$/tonne';
  public static readonly $_BU = '$/bu';

	public static readonly $_TONNE_ACCESSIBILITY = "dollars.per.tonne";
	public static readonly $_BU_ACCESSIBILITY = "dollars.per.bushel";

  // Cost of Nitrogen
  public static readonly $_KG = '$/kg';
  public static readonly $_LB = '$/lb';

	public static readonly $_KG_ACCESSIBILITY = "dollars.per.kilogram";
	public static readonly $_LB_ACCESSIBILITY = "dollars.per.pound";

  // Per Area
  public static readonly _HA = '/ha';
  public static readonly _AC = '/ac';

	public static readonly _HA_ACCESSIBILITY = "per.hectare";
	public static readonly _AC_ACCESSIBILITY = "per.acre";

  // Per Mass
  public static readonly _KG = '/kg';
  public static readonly _LB = '/lb';

	public static readonly _KG_ACCESSIBILITY = "per.kilogram";
	public static readonly _LB_ACCESSIBILITY = "per.pound";

  // Per Mass Large
  public static readonly _TONNE = '/tonne';
  public static readonly _TON = '/ton';

	public static readonly _TONNE_ACCESSIBILITY = "per.tonne";
	public static readonly _TON_ACCESSIBILITY = "per.ton";

  // Density
  public static readonly KG_L = 'kg/L';
  public static readonly LB_GAL = 'lb/gal';
  public static readonly KB_CU_M = 'kg/m³';
  public static readonly LB_CU_FT = 'lb/ft³';

	public static readonly KG_L_ACCESSIBILITY = "kilograms.per.liter";
	public static readonly LB_GAL_ACCESSIBILITY = "pounds.per.gallon";
	public static readonly KB_CU_M_ACCESSIBILITY = "kilograms.per.cubic.meter";
	public static readonly LB_CU_FT_ACCESSIBILITY = "pounds.per.cubic.foot";

  // Angle
  public static readonly DEGREE = '°';

	public static readonly DEGREE_ACCESSIBILITY = "degrees";

  // Temperature
  public static readonly CELSIUS = '°C';
  public static readonly FAHRENHEIT = '°F';

	public static readonly CELSIUS_ACCESSIBILITY = "degrees.celsius";
	public static readonly FAHRENHEIT_ACCESSIBILITY = "degrees.fahrenheit";

  // kWh
  public static readonly KWH = 'kWh';

	public static readonly KWH_ACCESSIBILITY = "kilowatt.hours";

  // value is the actual function name in converter service
  public static readonly NO_CONVERSION = 'NO_CONVERSION';
  public static readonly UNIT_TYPE_AREA = 'area';
  public static readonly UNIT_TYPE_APP_MASS = 'appMass';
  public static readonly UNIT_TYPE_APP_VOLUME = 'appVolume';
  public static readonly UNIT_TYPE_VOLUME = 'volume';
  public static readonly UNIT_TYPE_VOLUME_FLOW_RATE = 'volumeFlowRate';
  public static readonly UNIT_TYPE_LIQUID_VOLUME = 'volumeLiquid';
  public static readonly UNIT_TYPE_DENSITY = 'density';
  public static readonly UNIT_TYPE_LIQUID_AMOUNT = 'amountLiquid';
  public static readonly UNIT_TYPE_MASS_PER_MASS = 'massPerMass';
  public static readonly UNIT_TYPE_APP_MASS_LARGE = 'appMassLarge';
  public static readonly UNIT_TYPE_LENGTH = 'length';
  public static readonly UNIT_TYPE_MASS_LARGE = 'massLarge';
  public static readonly UNIT_TYPE_MASS = 'mass';
  public static readonly UNIT_TYPE_LENGTH_LARGE = 'large';
  public static readonly UNIT_TYPE_LENGTH_SMALL = 'small';
  public static readonly UNIT_TYPE_SMALL2 = 'small2';
  public static readonly UNIT_TYPE_VOLUME2 = 'volume2';
  public static readonly UNIT_TYPE_VOLUME2_S = 'volume2PerSecond';
  public static readonly UNIT_TYPE_DENSITY2 = 'density2';
  public static readonly UNIT_TYPE_VOLUME3 = 'volume3';
  public static readonly UNIT_TYPE_DIAMETER = 'diameter';
  public static readonly UNIT_TYPE_AREA2 = 'area2';
  public static readonly UNIT_TYPE_YIELD = 'yield';
  public static readonly UNIT_TYPE_AREA_COST = 'areaCost';
  public static readonly UNIT_TYPE_APP_MASS_COST = 'massCost';
  public static readonly UNIT_TYPE_APP_MASS_LARGE_COST = 'massLargeCost';
  public static readonly UNIT_TYPE_VOLUME_M3_COST = 'volumeM3Cost';
  public static readonly UNIT_TYPE_ANNUAL_PERCIPITATION = 'annualPercipitation';
  public static readonly UNIT_TYPE_LENGTH_M_FT = 'lengthMeterFoot';
  public static readonly UNIT_TYPE_LENGTH_M_FT_S = 'lengthMeterFootPerSecond';
  public static readonly UNIT_TYPE_YIELD_PRICE = 'yieldPrice';
  public static readonly UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA = 'hortiAmountArea';
  public static readonly UNIT_TYPE_VOLUME_AREA = 'volumeArea';
  public static readonly UNIT_TYPE_TEMP = 'temperature';
  public static readonly UNIT_TYPE_KWH = 'kwh';
  public static readonly UNIT_TYPE_SOIL_EROSION = 'soilErosion';
  public static readonly UNIT_TYPE_SOIL_EROSION_Y = 'soilErosionPerYear';
}
