<div [formGroup]="f" id="calculatorHeader" class="print--hide">
    <mat-toolbar>
        <mat-toolbar-row class="flex flex--cols-2">
            <!-- left end -->
            <div class="flex-child flex-child--flex-grow flex flex--cols-3 flex--nowrap">
                <a
                    class="button button--green-tertiary flex-child flex-child--flex-shrink"
                    [routerLink]="routerLinkHome"
                    matTooltip="{{ 'calculator.home.tooltip.text' | translate | async }}"
                    matTooltipPosition="above"
                    attr.aria-label="{{ 'home.topnav.sidenav.menuitem.home' | translate | async }}">
                    <mat-icon aria-hidden="true">home</mat-icon>
                </a>
                <!-- calculator name -->
                <h2 class="h2 h2--calculator-title flex-child flex-child--flex-shrink" *ngIf="!editMode">
                    {{ name.value }}
                </h2>
                <mat-form-field class="flex-child flex-child--flex-shrink" [style.width.em]="18" color="accent" floatLabel="never" *ngIf="editMode">
                    <input
                        id="calculatorNameId"
                        matInput
                        trim="blur"
                        autoFocus
                        (change)="nameFn()"
                        (keydown.enter)="nameFn()"
                        [errorStateMatcher]="matcher"
                        placeholder="{{ 'calculator.name.placeholder' | translate | async }}"
                        formControlName="name"
                        maxlength="30" />
                    <mat-error *ngIf="name.errors?.minlength">
                        {{ "validation.message.minLength.3" | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="name.errors?.maxlength">
                        {{ "validation.message.maxLength.30" | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="name.errors?.empty">
                        {{ "validation.message.empty" | translate | async }}
                    </mat-error>
                </mat-form-field>
                <button
                    type="button"
                    *ngIf="!editMode"
                    mat-icon-button
                    class="button button--green-tertiary print--hide flex-child flex-child--flex-shrink"
                    matTooltip="{{ 'calculator.name.change' | translate | async }}"
                    matTooltipPosition="above"
                    (click)="toggleEditMode()"
					[title]="'change.calculator.name' | translate | async">
                    <mat-icon aria-hidden="true">edit</mat-icon>
                </button>
            </div>

            <!-- right end -->
            <div class="flex-child flex-child--flex-shrink flex flex--cols-none flex--nowrap">
                <!-- measurement system switch -->
                <mat-menu #unitsMenu="matMenu">
                    <button type="button" *ngFor="let item of measurementSystem | enumToArray" mat-menu-item tabindex="0" (click)="switchMeasurementSystem(item.index)">
                        {{ measurementSystem[item.index] | translate | async }}
                    </button>
                </mat-menu>
                <button
                    type="button"
                    mat-button
                    class="icon-button toolbar-buttons print--hide"
                    [matMenuTriggerFor]="unitsMenu"
                    id="units-button"
                    matTooltip="{{ 'calculator.unit.change.tooltip.text' | translate | async }}"
                    matTooltipPosition="below"
					(click)="unitChangerAccessibilityApplications();">
                    {{ measurementSystemName | translate | async }}
                    <span class="material-icons" iconPositionEnd> expand_more </span>
                </button>
                <!-- flag summary -->
                <ng-container *ngIf="calculatedFlagSummary$ | async as flagSummary">
                    <button
                        *ngIf="flagSummaryExists && !!flagSummary"
                        type="button"
                        class="button button--green-tertiary print--hide flex-child flex-child--flex-shrink"
                        matTooltipPosition="above"
                        (click)="openFlagSummaryDialog()"
                        matTooltip="{{ 'flag.summary.tooltip.text' | translate | async }}"
                        matTooltipPosition="below">
						<span class="accessibleFlagFix" [innerHTML]="'flag.summary.title' | translate | async"></span>
                        <mat-icon
                            [matBadgeHidden]="isMatBadgeHidden(flagSummary)"
                            matBadge="{{ flagSummary?.displayFlagCount }}"
                            class="{{ flagSummary?.displayFlagLevelId | flagClassBadgePipe }}"
                            MatBadgeSize="small">
								flag
                        </mat-icon>
                    </button>
                </ng-container>

                <button
                    type="button"
                    class="button button--green-tertiary print--hide flex-child"
                    matTooltip="{{ 'calculator.header.ellipsis.tooltip' | translate | async }}"
                    [matMenuTriggerFor]="ellipsisMenu"
                    *ngIf="showWorksheetFunctionsMenuButton">
                    <span class="material-icons"> more_vert </span>
                </button>

                <mat-menu #ellipsisMenu="matMenu">
                    <button type="button" mat-menu-item (click)="openDeleteFieldInputsDialog()" [disabled]="isDeleteFieldInputsHidden()">
                        <span [ngClass]="isDeleteFieldInputsHidden() ? '' : 'colour--red-primary'">
                            {{ "calculator.header.ellipsis.menu.delete-field-inputs" | translate | async }}
                        </span>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
