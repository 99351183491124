@use "base";
@use "sass:map";

[data-createNewDialogComponent] {
  max-width: base.multiply-two-numbers(base.$size, 40) !important;
  max-height: 80vh !important;

  @media (min-width: base.map-deep-get(base.$breakpoints, "tablet", "min")) {
    width: base.multiply-two-numbers(base.$size, 35) !important;
  }

  .wrap-option {
    line-height: unset !important;
    white-space: normal !important;
    height: base.multiply-two-numbers(base.$size, 3.2) !important;
  }

  .mat-mdc-optgroup-label {
    font-weight: map.get(base.$font-weights, "bold") !important;
  }
}
