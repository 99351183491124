@use "base";
@use "sass:map";

@include base.show-hide();

footer[data-footerComponent] {
  position: relative;
  z-index: 0;

  * {
    font-size: base.$size;
  }

  [data-toolbar-bottom] {
    position: relative;
    z-index: 0;
    /* Ensure that app's content doesn't overlap the toolbar */
    bottom: 0;
    width: 100%;
    background: base.map-deep-get(base.$colours, grey, "secondary");
    padding-top: base.$size;
    padding-bottom: base.$size;
    font-weight: map.get(base.$font-weights, "medium");
    margin: 0;

    p {
      margin: 0;

      @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
        margin-bottom: base.$size;
      }
    }

    div {
      margin: 0;

      button {
        margin: 0;
      }
    }

    @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
      > .flex-child {
        text-align: center;
      }
    }
  }

  > [data-bottom-footer-navigation].flex.flex--cols-2 {
    padding: 0;
    background-color: base.map-deep-get(base.$colours, grey, "primary");

    @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
      padding: base.$size !important;
    }

    > .flex-child.flex {
      padding: 0;
      margin: 0;

      > .flex-child {
        padding: base.$size;
        font-size: base.$size;
        margin: 0;
        color: base.map-deep-get(base.$colours, black, "primary") !important;

        @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
          padding: 0 !important;
          width: 100%;
          margin-bottom: base.$size;
          text-align: center;
        }
      }

      @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
        &:last-child {
          width: 100% !important;

          > .flex-child {
            &:last-child {
              margin-bottom: 0;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
  }

  [data-footerLogoContainer].flex.flex--cols-3 {
    > .flex-child {
      margin-top: 0;

      @media (max-width: base.map-deep-get(base.$breakpoints, "tablet", "max")) and (min-width: base.map-deep-get(base.$breakpoints, "tablet", "min")) {
        width: base.divide-two-numbers(100%, 3);
      }
    }
  }

  > .flex.flex--cols-3 {
    margin: 0;
  }

  .canada-image {
    padding: base.$size;

    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
      padding: base.multiply-two-numbers(base.$size, 1.5);
    }
  }

  .ontario-image {
    padding: base.multiply-two-numbers(base.$size, 1.375);
  }

  .cap-image {
    padding: base.divide-two-numbers(base.$size, 2);
  }
}

footer.print--show {
  padding: base.$size;
  position: relative;

  p.print--show {
    text-align: right;
  }
}
