@use "base";

[data-flagDialogComponent] {
  .flex.flex--cols-2 {
    align-items: baseline;

    > .flex-child {
      &.mat-icon {
        //margin-right: base.$size;
        margin-right: 0;
        padding-right: 0;
      }

      &:nth-child(2) {
        margin-top: base.multiply-two-numbers(base.$size, 0.4);
      }

      @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
        width: 50%;
      }
    }
  }
}
