@use "base";
@use "sass:map";

.bold-label {
  font-weight: map.get(base.$font-weights, "medium");
}

.sr-only {
  display: none;
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
}

.close.mat-mdc-button {
  background: base.map-deep-get(base.$colours, white, "primary") !important;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  line-height: 14px;
  min-width: auto;
}
