import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {ConversionConstants} from "@shared/constants/conversion-constants";
import {map, Observable, of} from "rxjs";

@Injectable(
	{
		providedIn: "root"
	}
)

@Pipe(
	{
		name: "unitDescriptionPipe",
		standalone: false
	}
)

export class UnitDescriptionPipe implements PipeTransform
{
	#map: Record<string, string> =
	{
		[ConversionConstants.CM]: ConversionConstants.CM_ACCESSIBILITY,
		[ConversionConstants.M]: ConversionConstants.M_ACCESSIBILITY,
		[ConversionConstants.M_S]: ConversionConstants.M_S_ACCESSIBILITY,
		[ConversionConstants.MM]: ConversionConstants.MM_ACCESSIBILITY,
		[ConversionConstants.KM]: ConversionConstants.KM_ACCESSIBILITY,
		[ConversionConstants.IN]: ConversionConstants.IN_ACCESSIBILITY,
		[ConversionConstants.FT]: ConversionConstants.FT_ACCESSIBILITY,
		[ConversionConstants.FT_S]: ConversionConstants.FT_S_ACCESSIBILITY,
		[ConversionConstants.MI]: ConversionConstants.MI_ACCESSIBILITY,
		[ConversionConstants.CU_M]: ConversionConstants.CU_M_ACCESSIBILITY,
		[ConversionConstants.CU_FT]: ConversionConstants.CU_FT_ACCESSIBILITY,
		[ConversionConstants.CU_M_S]: ConversionConstants.CU_M_S_ACCESSIBILITY,
		[ConversionConstants.CU_FT_S]: ConversionConstants.CU_FT_S_ACCESSIBILITY,
		[ConversionConstants.L]: ConversionConstants.L_ACCESSIBILITY,
		[ConversionConstants.GAL]: ConversionConstants.GAL_ACCESSIBILITY,
		[ConversionConstants.US_GAL]: ConversionConstants.US_GAL_ACCESSIBILITY,
		[ConversionConstants.CU_YD]: ConversionConstants.CU_YD_ACCESSIBILITY,
		[ConversionConstants.L_HA]: ConversionConstants.L_HA_ACCESSIBILITY,
		[ConversionConstants.GAL_AC]: ConversionConstants.GAL_AC_ACCESSIBILITY,
		[ConversionConstants.CU_M_HA]: ConversionConstants.CU_M_HA_ACCESSIBILITY,
		[ConversionConstants.US_GAL_AC]: ConversionConstants.US_GAL_AC_ACCESSIBILITY,
		[ConversionConstants.L_SQ_M]: ConversionConstants.L_SQ_M_ACCESSIBILITY,
		[ConversionConstants.GAL_SQ_FT]: ConversionConstants.GAL_SQ_FT_ACCESSIBILITY,
		[ConversionConstants.US_GAL_SQ_FT]: ConversionConstants.US_GAL_SQ_FT_ACCESSIBILITY,
		[ConversionConstants.LPS]: ConversionConstants.LPS_ACCESSIBILITY,
		[ConversionConstants.CFM]: ConversionConstants.CFM_ACCESSIBILITY,
		[ConversionConstants.KG]: ConversionConstants.KG_ACCESSIBILITY,
		[ConversionConstants.LB]: ConversionConstants.LB_ACCESSIBILITY,
		[ConversionConstants.TONNE]: ConversionConstants.TONNE_ACCESSIBILITY,
		[ConversionConstants.TON]: ConversionConstants.TON_ACCESSIBILITY,
		[ConversionConstants.KG_HA]: ConversionConstants.KG_HA_ACCESSIBILITY,
		[ConversionConstants.LB_AC]: ConversionConstants.LB_AC_ACCESSIBILITY,
		[ConversionConstants.KG_TONNE]: ConversionConstants.KG_TONNE_ACCESSIBILITY,
		[ConversionConstants.LB_TON]: ConversionConstants.LB_TON_ACCESSIBILITY,
		[ConversionConstants.TONNE_HA]: ConversionConstants.TONNE_HA_ACCESSIBILITY,
		[ConversionConstants.TON_AC]: ConversionConstants.TON_AC_ACCESSIBILITY,
		[ConversionConstants.TONNE_HA_YEAR]: ConversionConstants.TONNE_HA_YEAR_ACCESSIBILITY,
		[ConversionConstants.TON_AC_YEAR]: ConversionConstants.TON_AC_YEAR_ACCESSIBILITY,
		[ConversionConstants.BU_AC]: ConversionConstants.BU_AC_ACCESSIBILITY,
		[ConversionConstants.TONHAC]: ConversionConstants.TONHAC_ACCESSIBILITY,
		// [ConversionConstants.TONAC]: ConversionConstants.TONAC_ACCESSIBILITY, -----THIS IS DUPLICATED BY ConversionConstants.TON_AC-----
		[ConversionConstants.HA]: ConversionConstants.HA_ACCESSIBILITY,
		[ConversionConstants.AC]: ConversionConstants.AC_ACCESSIBILITY,
		[ConversionConstants.SQ_M]: ConversionConstants.SQ_M_ACCESSIBILITY,
		[ConversionConstants.SQ_FT]: ConversionConstants.SQ_FT_ACCESSIBILITY,
		[ConversionConstants.$_HA]: ConversionConstants.$_HA_ACCESSIBILITY,
		[ConversionConstants.$_AC]: ConversionConstants.$_AC_ACCESSIBILITY,
		[ConversionConstants.$_TONNE]: ConversionConstants.$_TONNE_ACCESSIBILITY,
		[ConversionConstants.$_BU]: ConversionConstants.$_BU_ACCESSIBILITY,
		[ConversionConstants.$_KG]: ConversionConstants.$_KG_ACCESSIBILITY,
		[ConversionConstants.$_LB]: ConversionConstants.$_LB_ACCESSIBILITY,
		[ConversionConstants._HA]:	ConversionConstants._HA_ACCESSIBILITY,
		[ConversionConstants._AC]: ConversionConstants._AC_ACCESSIBILITY,
		[ConversionConstants._KG]: ConversionConstants._KG_ACCESSIBILITY,
		[ConversionConstants._LB]: ConversionConstants._LB_ACCESSIBILITY,
		[ConversionConstants._TONNE]: ConversionConstants._TONNE_ACCESSIBILITY,
		[ConversionConstants._TON]: ConversionConstants._TON_ACCESSIBILITY,
		[ConversionConstants.KG_L]: ConversionConstants.KG_L_ACCESSIBILITY,
		[ConversionConstants.LB_GAL]: ConversionConstants.LB_GAL_ACCESSIBILITY,
		[ConversionConstants.KB_CU_M]: ConversionConstants.KB_CU_M_ACCESSIBILITY,
		[ConversionConstants.LB_CU_FT]: ConversionConstants.LB_CU_FT_ACCESSIBILITY,
		[ConversionConstants.DEGREE]: ConversionConstants.DEGREE_ACCESSIBILITY,
		[ConversionConstants.CELSIUS]: ConversionConstants.CELSIUS_ACCESSIBILITY,
		[ConversionConstants.FAHRENHEIT]: ConversionConstants.FAHRENHEIT_ACCESSIBILITY,
		[ConversionConstants.KWH]: ConversionConstants.KWH_ACCESSIBILITY
	};

	transform(value: Observable<string> | string): Observable<string>
	{
		if(typeof value === "string")
		{
			return of(this.#map[value] || value);
		}

		return value.pipe(map((result: string) =>
		{
			return this.#map[result] || result;
		}));
	}
}