@use "base";

[data-lcoationLotDialogComponent] {
  .mat-mdc-dialog-content {
    width: base.multiply-two-numbers(base.$size, 29);

    > .mat-mdc-form-field {
      width: 100%;
    }
  }

  .mat-mdc-checkbox {
    width: 100%;
    text-align: right;
    display: block;
    margin-top: base.multiply-two-numbers(base.$size, base.negate-number(1)) 0.3;
    margin-bottom: base.$size;
  }

  .flex {
    .mat-mdc-checkbox.flex-child {
      width: auto;
      margin: 0;
      margin-right: base.$size;
      text-align: left;
    }
  }

  .mdc-dialog__title {
    display: flex !important;
    align-items: baseline !important;
  }
}
