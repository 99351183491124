@use "base";

app-calculator-heading {
  display: block;
  position: sticky;
  top: 0;
  background-color: base.map-deep-get(base.$colours, white, "primary");
  z-index: 2;
}

#calculatorHeader {
  mat-toolbar {
    background-color: base.map-deep-get(base.$colours, white, "primary");

    mat-toolbar-row.flex {
      max-height: base.multiply-two-numbers(base.$size, 2.1);
      margin-top: base.multiply-two-numbers(base.$size, 1.25);

      > .flex-child {
        margin-top: base.$size;
        width: 50%;

        &:first-child {
          margin: 0;
          align-items: center;

          > .flex-child {
            margin: 0;
            align-self: center;

            &:first-child,
            &:last-child {
              padding: 0;
            }

            &:nth-child(2):not(mat-form-field) {
              padding-left: base.divide-two-numbers(base.$size, 2);
              padding-right: base.divide-two-numbers(base.$size, 2);
            }

            .mat-icon {
              font-size: base.multiply-two-numbers(base.$size, 1.7) !important;
              cursor: inherit;
            }
          }
        }

        &:last-child {
          margin-top: 0;
          margin-bottom: 0;

          .flex-child {
            margin-left: base.$size;

            @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
              margin-left: base.multiply-two-numbers(base.$size, 0.5);
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }

        .h2.h2--calculator-title {
          max-width: calc(100vw - #{base.multiply-two-numbers(base.$size, 16)});
          min-width: base.multiply-two-numbers(base.$size, 3.2);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      mat-icon,
      mat-icon.mat-icon,
      .mat-icon {
        margin-top: 0;
      }
    }
  }

  #units-button {
    padding-right: 0;
  }

	.accessibleFlagFix
	{
		overflow: hidden;
		position: absolute;
		height: 0;
		width: 0;
	}
}