@use "base";

[data-signInToolbar] {
  * {
    font-size: base.$size;
  }

  @media print {
    display: none;
  }

  mat-toolbar {
    font-size: base.$size;
    background-color: base.map-deep-get(base.$colours, green, "primary");
    color: base.map-deep-get(base.$colours, white, "primary");
    height: auto;

    div.flex-child {
      margin-top: 0;

      p {
        margin-top: base.$size;
      }
    }
  }

  .flex {
    justify-content: center;
    margin-bottom: 0;

    &.flex--cols-2 {
      > .flex-child:not(.flex-child--full-width):not(button) {
        width: 50%;

        &:first-child {
          padding-right: 0;

          > .button {
            margin-right: 0;
          }
        }

        &:last-child {
          padding-left: 0;

          > .button {
            margin: 0;
          }
        }
      }
    }
  }
}
