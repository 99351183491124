@use "base";

[data-locationInformationComponent] {
  button {
    height: auto;
    min-height: var(--mdc-outlined-button-container-height);
  }

  .table {
    margin-bottom: 0;

    th,
    td {
      vertical-align: middle;

      &:last-child {
        white-space: nowrap;
      }

      .button {
        margin: 0;
        padding: 0;

        &:last-child {
          margin-left: base.$size;
        }

        &:only-child {
          margin-left: 0;
        }

        .mat-icon {
          margin: 0;
          padding: 0;
        }
      }

      &:nth-child(6) {
        padding-right: 0;
        padding-left: 0;
      }

      @media (max-width: 643px) {
        &:nth-child(5) {
          display: none;
        }
      }

      @media (max-width: 573px) {
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }

      @media (max-width: 476px) {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }

      @media (max-width: 429px) {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }

  .flex--cols-2 {
    margin-bottom: 0;

    &.flex--cols-2-flag {
      > .fieldset {
        margin-bottom: 0;
      }
    }

    > .fieldset {
      .flex--cols-2.flex--cols-2-flag {
        .mat-mdc-form-field:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  > .mat-mdc-form-field:last-child {
    width: 100%;
    margin-bottom: 0;
  }

  > .fieldset {
    width: 49.5%;
  }

  .fieldset {
    .button:first-of-type {
      margin-left: 0;
    }

    > .flex {
      margin-bottom: 0 !important;

      &.flex--cols-2 {
        .flex-child.flex-child--flex-shrink {
          .button {
            margin: 0;
            padding: 0;

            .mat-icon {
              margin: 0;
            }
          }

          app-flag {
            .button {
              margin-left: base.$size !important;
            }

            &:first-of-type {
              .button {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
