@use "base";

[data-filterMenuComponent]
{
	.mat-mdc-dialog-content
	{
	    width: base.multiply-two-numbers(base.$size, 25) !important;
  	}

	.mat-mdc-dialog-actions
	{
		> div.flex-child
		{
			display: flex;
			align-items: flex-end;

			@media(max-width: 437px)
			{
				button
				{
					height: base.multiply-two-numbers(base.$size, 3);
				}
			}
		}
	}
}