@mixin show-hide //Came with the application
{
	.hidden{display: none !important;}
    .show-block{display: block !important;}
    .show-flex{display: flex !important;}
}

@mixin flex-child($cols) //Sets the number of columns for layouts
{
	width: divide-two-numbers(100%, $cols);
}

@mixin flex-child-important($cols) //Sets the number of columns for layouts adding !important
{
	width: divide-two-numbers(100%, $cols) !important;
}

@mixin width($width, $important: null) //Sets width with an optional !important property
{
	@if $important == null
	{
		width: $width;
	}
	@else
	{
		width: $width !important;
	}
}

@mixin text-colour($colour, $colourVariation, $important: null) //Sets colour with an optional !important property
{
	@if $important == null
	{
		color: map-deep-get($colours, $colour, $colourVariation);
	}
	@else
	{
		color: map-deep-get($colours, $colour, $colourVariation) !important;
	}
}

@mixin button--primary($backgroundColour, $backgroundColourType, $colour: null, $colourVariation: null)
{
	background-color: map-deep-get($colours, $backgroundColour, $backgroundColourType);

	@if $colour == null or $colourVariation == null
	{
		color: map-deep-get($colours, white, "primary") !important;
	}
	@else
	{
		color: map-deep-get($colours, $colour, $colourVariation) !important;
	}
}

@mixin button--secondary($colour, $colourType)
{
	background-color: map-deep-get($colours, white, "primary");
	color: map-deep-get($colours, $colour, $colourType) !important;
	border: multiply-two-numbers($size, 0.1) solid map-deep-get($colours, $colour, $colourType);
}

@mixin button--tertiary($colour, $colourType)
{
	background-color: map-deep-get($colours, transparent, "primary");
	color: map-deep-get($colours, $colour, $colourType) !important;
	border: none;
}

@mixin button--hover-focus($colour, $colourType)
{
	box-shadow: 0 0 $size map-deep-get($colours, $colour, $colourType);
}

@mixin badge($colour, $colourType)
{
	background: map-deep-get($colours, $colour, $colourType) !important;
	color: map-deep-get($colours, white, "primary") !important;
}

@mixin header($fontSize, $marginTop, $marginBottom, $marginLeft, $marginRight)
{
	font-size: multiply-two-numbers($size, $fontSize);
	margin-top:  multiply-two-numbers($size, $marginTop);
	margin-bottom:  multiply-two-numbers($size, $marginBottom);
	margin-left:  multiply-two-numbers($size, $marginLeft);
	margin-right:  multiply-two-numbers($size, $marginRight);
}

@mixin flex--cols-2-and-print($columnCount)
{
	@include flex-child($columnCount); //Mixin creates 2 column layout

	&:nth-child(odd) //Every odd element containing "flex-child" class
	{
		padding-right: divide-two-numbers($size, 2);
	}

	&:nth-child(even) //Every even element containing "flex-child" class
	{
		padding-left: divide-two-numbers($size, 2);
	}

	&.flex-child--important //A child element with "flex flex--cols flex-child" classes
	{
		@include flex-child-important(2);
	}

	&.flex-child--odd-child
	{
		padding-left: 0;
		padding-right: divide-two-numbers($size, 2);
	}

	&.flex-child--even-child
	{
		padding-left: divide-two-numbers($size, 2);
		padding-right: 0;
	}
}

@mixin flex--cols-3-and-print($columnCount)
{
	@include flex-child($columnCount); //Mixin creates 3 column layout

	&:nth-child(3n + 1) //Every first "flex-child" element in a row
	{
		padding-left: 0;
		padding-right: divide-two-numbers($size, 2);
	}

	&:nth-child(3n + 2) //Every second "flex-child" element in a row
	{
		padding-left: divide-two-numbers($size, 2);
		padding-right: divide-two-numbers($size, 2);
	}

	&:nth-child(3n + 3) //Every third "flex-child" element in a row
	{
		padding-left: divide-two-numbers($size, 2);
		padding-right: 0;
	}

	&.flex-child--two-third-width //A "flex-child that uses 2/3 of the available width
	{
		width: multiply-two-numbers(divide-two-numbers(100%, 3), 2) !important;
		padding-left: 0;
	}
}

@mixin flex--cols-5-tablet-and-print($columnCount)
{
	@include flex-child($columnCount); //Mixin creates 3 column layout

	&:nth-child(3n + 1) //Every first "flex-child" element in a row
	{
		padding-left: 0;
		padding-right: divide-two-numbers($size, 2);
		margin-bottom: $size;
	}

	&:nth-child(3n + 2) //Every second "flex-child" element in a row
	{
		padding-left: divide-two-numbers($size, 2);
		padding-right: divide-two-numbers($size, 2);
		margin-bottom: $size;
	}

	&:nth-child(3n + 3) //Every third "flex-child" element in a row
	{
		padding-left: divide-two-numbers($size, 2);
		padding-right: 0;
	}

	&:nth-last-child(3),
	&:nth-last-child(2),
	&:nth-last-child(1)
	{
		margin-bottom: 0;
	}
}