import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LinkService } from '@core/services/link.service';
import { SessionService } from '@core/services/session.service';
import { WorksheetService } from '@home/service/worksheet.service';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AODA } from '../../../core/utilities/aoda';
import { LanguageType } from '../../models/common/language-type.enum';
import { BaseComponent } from '../base.component';
import {MessageConstants} from "@shared/constants/message.constants";
import {MessageService} from "@shared/services/message.service";

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TopNavComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('drawer', { static: true }) public drawer: MatSidenav;
  sideNavOpened: boolean;

  get routerLinkHome() {
    return this.isAuthenticated ? '/secure-home' : '/';
  }

  get oppositeLanguage() {
    const preference = this.cache.preference;
    return preference.languageType === LanguageType.English ? LanguageType.French : LanguageType.English;
  }

  get allowCreateNew() {
    const worksheetTypes = this.cache.worksheetTypes;
    const url = window.location.href;
    return worksheetTypes ? !worksheetTypes.find(v => url.toUpperCase().indexOf(v.typeName.toUpperCase()) > -1) : false;
  }

  get version() {
    return environment.isProdEnv ? undefined : 'Commit f7ef53061 (2025-02-14)';
  }

  constructor(
    private injector: Injector,
    public router: Router,
    private session: SessionService,
    private el: ElementRef,
    private renderer: Renderer2,
    private linkService: LinkService,
    private worksheetService: WorksheetService,
	private messageService: MessageService
  ) {
    super(injector);
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.renderer, this.languageService.languageType);
  }

  logout() {
    this.session.logout();
  }

  login() {
    this.session.login();
  }

  signup() {
    this.session.signup();
  }

  forgotPassword() {
    return this.session.forgotPassword();
  }

  home() {
    this.sideNavOpened = !this.sideNavOpened;
    this.session.home();
  }

  contactUs() {
    this.sideNavOpened = false;
    this.linkService.contactUs();
  }

  feedback() {
    this.sideNavOpened = false;
    this.linkService.feedback();
  }

  switchLanguage() {
    this.session
      .switchLanguage()
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  createNew(): void {
    this.sideNavOpened = !this.sideNavOpened;
    this.worksheetService.create().subscribe();
  }

	import(): void
	{
		this.sideNavOpened = !this.sideNavOpened;

		this.messageService.sendMessage(MessageConstants.IMPORT_WORKSHEET);
	}
}
