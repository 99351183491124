@use "base";

.page-container {
  .mat-mdc-dialog-content {
    width: base.multiply-two-numbers(base.$size, 23.75);
  }

  .content {
    .red-text {
      color: base.map-deep-get(base.$colours, red, "primary");
    }

    .btn-add-label {
      bottom: base.multiply-two-numbers(base.$size, 2);
    }
  }

  .border-line {
    margin: base.$size 0 base.$size 0;
  }

  .footer {
    margin-top: base.$size;
    text-align: right;
  }
}
