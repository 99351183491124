@use "base";

[data-convertWorksheetComponent] {
  max-width: base.multiply-two-numbers(base.$size, 30) !important;
  max-height: 80vh !important;
}

.form-container {
  display: flex;
  flex-direction: column;
}
