@use "base";

button#sideNavOpen.button.flex {
  position: fixed;
  top: 50%;
  left: unset;
  margin: 0;
  border: solid base.multiply-two-numbers(base.$size, 0.1)
    base.map-deep-get(base.$colours, green, "primary");
  box-shadow: base.divide-two-numbers(base.$size, 4) 0
    base.divide-two-numbers(base.$size, 4)
    base.map-deep-get(base.$colours, grey, "secondary");
  background-color: base.map-deep-get(base.$colours, transparent, "primary");
  z-index: 3;
  transition: opacity 1s, visibility 1s, left 0.28s;
  border-radius: 0 (base.multiply-two-numbers(base.$size, 0.4))
    (base.multiply-two-numbers(base.$size, 0.4)) 0;

  &:hover {
    background-color: base.map-deep-get(base.$colours, white, primary);
  }

  &.hideButton {
    opacity: 0;
    visibility: hidden !important;
    left: base.multiply-two-numbers(base.$size, 1.75);
    transition: opacity 1s, visibility 1s, left 0.28s;

    &-scrolled {
      opacity: 0;
      visibility: hidden !important;
      transition: opacity 1s, visibility 1s;
    }
  }

  span.flex-child {
    padding-top: base.multiply-two-numbers(base.$size, 2);
    padding-bottom: base.multiply-two-numbers(base.$size, 2);
    color: base.map-deep-get(base.$colours, green, primary);
  }
}
